.reshopModal {
  padding: 0 !important;
  text-align: center;
}
.reshopModal .modal-content {
  height: 100%;
  border-radius: 10px;
  background-color: #243f4b;
  opacity: 0.9;
  color: white;
  border: none;
  padding: 0;
}
.reshopModal .modal-body {
  overflow-y: auto;
  padding-top: 5%;
  border: none;
}

.reshopModal .modal-header {
  color: white;
  border: none;
}

.reshopModal .modal-footer {
  color: white;
  border: none;
  justify-content: center;
  padding-top: 2%;
  padding-bottom: 4%;
}
