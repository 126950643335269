html {
  font-size: 15px;
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Heebo", -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
