@keyframes circlePulse {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes circlePulse2 {
  0% {
    opacity: 0;
    /* border-radius: 10%; */
  }
  75% {
    opacity: 1;
    /* border-radius: 50%; */
  }
  100% {
    opacity: 0;
    /* border-radius: 10%; */
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circle-pulse-multiple .loader .circle-2 {
  animation-delay: -0.8s;
}
.circle-pulse-multiple .loader .circle-3 {
  animation-delay: -0.6s;
}
.loader-container .loader > div.dot {
  color: #6cb545;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #6cb545;

  animation: circlePulse 1.2s ease-in infinite;
  /* animation: rotate 1s ease infinite; */
}
.loader-container .loader > div.dot.dot-2 {
  animation-delay: -0.8s;
}
.loader-container .loader > div.dot.dot-3 {
  animation-delay: -0.4s;
}

.loader-container .loader2 > div.dot {
  color: #035c12;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #6cb545;

  animation: circlePulse2 1.2s ease-in infinite;
}
.loader-container .loader2 > div.dot.dot-2 {
  animation-delay: -0.8s;
}
.loader-container .loader2 > div.dot.dot-3 {
  animation-delay: -0.4s;
}

.loader-container .loader3 > div.dot {
  color: #6cb545;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #6cb545;

  animation: circlePulse3 1.2s ease-in infinite;
}
.loader-container .loader3 > div.dot.dot-2 {
  animation-delay: -0.8s;
}
.loader-container .loader3 > div.dot.dot-3 {
  animation-delay: -0.4s;
}

.loader-container {
  position: relative;
  float: left;
  width: 250px;
  height: 250px;
}
