.tooltip-inner {
  background-color: #358640;
  color: #ffffff;
  padding: 5px;
  max-width: 500px;
  border-radius: 10px;
}

.label {
  font-size: 14px;
  font-family: "Heebo", -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding-right: 50px;
}

.value {
  font-size: 14px;
  font-family: "Heebo", -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.coverageRequested {
  font-size: 14px;
  font-family: "Heebo", -apple-system, BlinkMacSystemFont, Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: bold;
}

.tooltip.show {
  opacity: 1;
}
.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #358640;
}
.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #358640;
}
.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #358640;
}
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #358640;
}

.tooltip.bottom .tooltip.right .tooltip.left .tooltip.top .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #358640;
  border-width: 0 5px 5px;
}

/* Custom Tooltip */

.combinedtext h4 {
  font-size: 1.4em;
  font-weight: 600;
  margin: 10px 0 30px;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

.combinedtext h5 {
  font-size: 0.9em;
  font-weight: 700;
  margin: 2px 0 4px;
  font-family: "Montserrat", sans-serif;
}

.combinedtext h6 {
  font-size: 0.8em;
  font-weight: 800;
  margin: 16px 0 4px;
  font-family: "Montserrat", sans-serif;
}

.combinedtext p {
  padding-bottom: 10px;
}

.combinedtext p:last-child {
  padding-bottom: 0;
}

.combinedtext .tooltip-inner {
  max-width: 300px !important;
}
