.modal {
  padding: 0 !important;
  text-align: left;
}
.vivintSmartHomeModal .modal-content {
  height: 100%;
  border-radius: 10px;
  background-color: #243f4b;
  opacity: 1;
  color: white;
  border: none;
  padding: 0;
}

.vivintSmartHomeModal .modal-header {
  color: white;
  border: none;
  display: block;
}

.vivintSmartHomeModal .modal-body {
  overflow-y: auto;
  padding-top: 2%;
  border: none;
}

.vivintSmartHomeModal .modal-dialog {
  max-width: 650px;
}

.vivintSmartHomeModal .closeButton {
  float: right;
  position: absolute;
  right: 12px;
  top: 12px;
}
