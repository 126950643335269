.radio-container {
  display: block;
  position: relative;
  padding-top: 1px;
  cursor: pointer;
  font-size: 15px;
  margin-left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  border: 1px solid #6d6e71;
  border-radius: 50%;
  background-color: white;
}

.checkmark.recalculating {
  background-color: #b3b4b6;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: rgb(0, 0, 0);
}

.label-text {
  position: absolute;
  left: 30px;
  top: 2px;
}
