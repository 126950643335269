.bs-popover-bottom .arrow::after {
  border-bottom-color: #358640;
}
.bs-popover-top .arrow::after {
  border-top-color: #358640;
}
.bs-popover-left .arrow::after {
  border-left-color: #358640;
}

.bs-popover-right .arrow::after {
  border-right-color: #358640;
}

.popover-header::before,
.bs-popover-bottom .popover-header::before {
  border-bottom: none;
}

.popover-header {
  background-color: #358640;
  color: white;
  border-bottom: none;
  width: 500px;
}

.popover-body {
  background-color: #358640;
  color: white;
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .popover-header {
    width: 350px;
  }
  .popover-body {
    width: 350px;
  }
}

@media only screen and (max-width: 350px) {
  .popover-header {
    width: 250px;
  }
  .popover-body {
    width: 250px;
  }
}
